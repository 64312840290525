import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import Inner1 from './pages/Inner1';
import Inner2 from './pages/Inner2';
import Inner3 from './pages/Inner3';
import Inner4 from './pages/Inner4';
import Inner5 from './pages/Inner5';
import Inner6 from './pages/Inner6';
import Inner7 from './pages/Inner7';
import Inner8 from './pages/Inner8';
import Inner9 from './pages/Inner9';
import Inner19 from './pages/Inner19';
import Inner18 from './pages/Inner18';
import Inner17 from './pages/Inner17';
import Inner16 from './pages/Inner16';
import Inner15 from './pages/Inner15';
import Inner14 from './pages/Inner14';
import Inner13 from './pages/Inner13';
import Inner12 from './pages/Inner12';
import Inner11 from './pages/Inner11';
import Inner10 from './pages/Inner10';
import JobDetails from './pages/JobDetails';
import JobForm from './components/JobForm';
import Home from './pages/Home';
import Industry4 from './pages/Industry4';
import CaseStudies from './pages/CaseStudies';
import Sectors from './pages/Sectors';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Career from './pages/Career';
import CareerInner from './pages/CareerInner';
import Refund from './pages/Refund';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Cookie from './pages/Cookie';
import PaymentGateway from './pages/PaymentGateway';
import TnC from './pages/TnC';
import NoPage from './pages/NoPage';
import ShippingPolicy from './pages/ShippingPolicy';
import { HelmetProvider } from 'react-helmet-async';

export function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Home />} />
            <Route path="industry4" element={<Industry4 />} />
            <Route path="case-studies" element={<CaseStudies />} />
            <Route path="sectors/:sector" element={<Sectors />} />
            <Route path="sectors" element={<Sectors />} />
            <Route path="services/:service" element={<Services />} />
            <Route path="services" element={<Services />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="career" element={<Career />} />
            <Route path="career-inner" element={<CareerInner />} />
            <Route path="case-studies/revolutionising-measurement-and-alignment-procedures" element={<Inner1 />} />
            <Route path="case-studies/reducing-downtime-and-enhancing-precise-turbine-boxup" element={<Inner2 />} />
            <Route path="case-studies/precise-revamping-and-modernization-for-offshore-platforms" element={<Inner3 />} />
            <Route path="case-studies/streamlining-project-management-for-green-hydrogen-plants" element={<Inner4 />} />
            <Route path="case-studies/as-built-digital-solutions-for-revamping-and-modernization" element={<Inner5 />} />
            <Route path="case-studies/indigenising-the-alignment-of-critical-diaphragms-with-ntpc" element={<Inner6 />} />
            <Route path="case-studies/precise-coke-drum-replacement" element={<Inner7 />} />
            <Route path="case-studies/periodical-health-assessment-of-storage-tanks" element={<Inner8 />} />
            <Route path="case-studies/reducing-costs-and-improving-quality-control" element={<Inner9 />} />
            <Route path="case-studies/contributing-to-efficient-power-generation-with-hydropower" element={<Inner10 />} />
            <Route path="case-studies/reducing-maintenance-downtime-to-just-30-minutes" element={<Inner11 />} />
            <Route path="case-studies/alignment-inspection-and-structural-integrity-solutions" element={<Inner12 />} />
            <Route path="case-studies/physical-parts-to-manufacturing-blueprints" element={<Inner13 />} />
            <Route path="case-studies/safe-and-efficient-hv-transformer-alignment" element={<Inner14 />} />
            <Route path="case-studies/enhancing-automotive-passenger-comfort" element={<Inner15 />} />
            <Route path="case-studies/curbing-oem-dependency-for-critical-component-fabrication" element={<Inner16 />} />
            <Route path="case-studies/end-to-end-owners-engineering-solutions-form" element={<Inner17 />} />
            <Route path="case-studies/comprehensive-engineering-solutions-to-power-plant-excellence" element={<Inner18 />} />
            <Route path="case-studies/structural-health-assessment-for-old-steel-structures" element={<Inner19 />} />
            <Route path="refund-policy" element={<Refund />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<Cookie />} />
            <Route path="payment-policy" element={<PaymentGateway />} />
            <Route path="tNc-policy" element={<TnC />} />
            <Route path="shipping-policy" element={<ShippingPolicy />} />
            <Route path="/job/:id" element={<JobDetails />} />
            <Route path="/jobform/:id" element={<JobForm />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
        
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
