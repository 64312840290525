import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

const Layout = () => {
  return (
    <>
        <div>
            <div  >
                <Header />
               
                <Outlet />
             
                <Footer/>
            </div>
        </div>
    </>
  )
}

export default Layout;
